import { NavigateFunction } from 'react-router-dom';

import {
  CallApiProviderInterface,
  CreateActivityArgs,
  ActivityData,
  ActivityPair, ApsCallData,
  CalculatePaymentsForActivityTickets,
  EntryActivity,
  PaymentFeatures,
} from '@zero5/call-modal';
import toast from '@zero5/ui/lib/utils/toast';

import {
  SessionApi,
  SessionResult,
  SessionResultType,
} from '@/api/SessionApi';

import { routes } from '@/routes';

import {
  SessionEndedError,
  SessionWasNotFound,
} from './errors';

export class CallApiProvider implements CallApiProviderInterface {
  private readonly sessionApi: SessionApi;
  private readonly navigate: NavigateFunction;

  constructor(
    sessionApi: SessionApi,
    navigate: NavigateFunction,
  ) {
    this.sessionApi = sessionApi;
    this.navigate = navigate;
  }

  handleError(error: unknown): void {
    if (error instanceof SessionEndedError) {
      this.navigate(routes.sessionEnded);
      return;
    }
    if (error instanceof SessionWasNotFound) {
      this.navigate(routes.sessionWasNotFound);
      return;
    }

    toast('error', String(error));
  }

  handleEndedCall(): void {
    this.navigate(routes.sessionEnded);
  }

  async handleEndCall(): Promise<void> {
    throw new Error('handleEndCall not Implemented');
  }

  async setNote(note: string | null): Promise<void> {
    const result = await this.sessionApi.setNote(note);
    CallApiProvider.processSessionResult(result);
  }

  async createEntryActivity(data: CreateActivityArgs): Promise<EntryActivity> {
    const result = await this.sessionApi.createEntryActivity(data);
    return CallApiProvider.processSessionResult(result);
  }

  async getSuggestedEntryActivitiesByLicensePlate(licensePlate: string): Promise<ActivityData[]> {
    const result = (await this.sessionApi.getSuggestedEntryActivitiesByLicensePlate(licensePlate));
    const processedResult = (CallApiProvider.processSessionResult(result))
      .map((entryActivity: EntryActivity) => entryActivity.data);

    return processedResult;
  }

  async updateExitActivity(activityId: string, mainLp: string): Promise<ActivityPair> {
    const result = await this.sessionApi.updateExitActivity({
      activityId,
      mainLp,
    });
    return CallApiProvider.processSessionResult(result);
  }

  async updateEntryForActivity(
    exitActivityId: string,
    entryActivityId: string,
    deviceId: string,
    tickets?: CalculatePaymentsForActivityTickets,
    paymentFeatures?: PaymentFeatures,
  ): Promise<ApsCallData> {
    const result = await this.sessionApi.updateEntryForActivity(
      exitActivityId,
      entryActivityId,
      deviceId,
      tickets,
      paymentFeatures,
    );
    return CallApiProvider.processSessionResult(result);
  }

  async openGate(): Promise<void> {
    const result = await this.sessionApi.openGate();
    return CallApiProvider.processSessionResult(result);
  }

  private static processSessionResult<T>(result: SessionResult<T>): T {
    switch (result.type) {
      case SessionResultType.SESSION_OK:
        return result.payload;

      case SessionResultType.SESSION_ENDED:
        throw new SessionEndedError();

      case SessionResultType.SESSION_NOT_FOUND:
        throw new SessionWasNotFound();
    }
  }
}
