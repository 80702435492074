import React from 'react';

import { InfoMessage } from '@/components/InfoMessage';

export const InvalidRequestPage: React.FC = () => {
  return (
    <InfoMessage
      text="Invalid Request"
    />
  );
};
