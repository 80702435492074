export enum SessionResultType {
  SESSION_OK = 'SESSION_OK',
  SESSION_ENDED = 'SESSION_ENDED',
  SESSION_NOT_FOUND = 'SESSION_NOT_FOUND',
}

export interface SessionOkResult<ResultType> {
  type: SessionResultType.SESSION_OK;
  payload: ResultType;
}

export interface SessionEndedResult {
  type: SessionResultType.SESSION_ENDED;
}

export interface SessionNotFoundResult {
  type: SessionResultType.SESSION_NOT_FOUND;
}

export type SessionResult<ResultType> =
  | SessionOkResult<ResultType>
  | SessionEndedResult
  | SessionNotFoundResult;
