import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createTheme, CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core';
import styled, { createGlobalStyle } from 'styled-components';

import themeOptions from '@zero5/ui/lib/styles/themeOptions';

import queryClient from '@/api/queryClient';

import { SessionApiProvider } from '@/components/SessionApiProvider';

import { routes } from '@/routes';
import { InvalidRequestPage } from '@/containers/InvalidRequestPage';
import { SessionEndedPage } from '@/containers/SessionEndedPage';
import { SessionWasNotFoundPage } from '@/containers/SessionWasNotFoundPage';
import { CallPage } from '@/containers/CallPage';
import { ErrorPage } from '@/containers/ErrorPage';

export const theme = createTheme(themeOptions);

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    font-size: 14px;
    scroll-behavior: smooth;
  }

  body {
    background-color: #ffffff;
    color: #393F49;
    font-size: 14px;
    font-family: Nunito Sans, sans-serif;
    letter-spacing: 0.01em;
  }
`;

export const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <CssBaseline />
            <GlobalStyle />

            <CenterWrapper>
              <Routes>
                <Route path={routes.root}>
                  <Route index element={<SessionApiProvider Child={CallPage} />} />
                  <Route path={routes.error} element={<ErrorPage />}/>
                  <Route path={routes.invalidRequest} element={<InvalidRequestPage />}/>
                  <Route path={routes.sessionEnded} element={<SessionEndedPage />}/>
                  <Route path={routes.sessionWasNotFound} element={<SessionWasNotFoundPage />}/>
                </Route>
              </Routes>
            </CenterWrapper>
          </StylesProvider>
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

const CenterWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; 
`;
