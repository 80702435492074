import React from 'react';
import { Navigate } from 'react-router-dom';

import { LoadingIndicator } from '@zero5/ui';

import { SessionApi, SessionResultType } from '@/api/SessionApi';

import { routes } from '@/routes';
import { CallAdapter } from '@/containers/CallAdapter';

interface Props {
  sessionId: string;
  sessionApi: SessionApi;
}

export const CallPage: React.FC<Props> = ({ sessionId, sessionApi }) => {
  const queryCallPayload = sessionApi.getSessionPayload();
  if (queryCallPayload.isError) {
    return <Navigate to={routes.error} />;
  }

  if (queryCallPayload.isLoading || !queryCallPayload.data) {
    return <LoadingIndicator />;
  }

  if (queryCallPayload.data.sessionPayload.type === SessionResultType.SESSION_NOT_FOUND
    || queryCallPayload.data.meetingCredentials.type === SessionResultType.SESSION_NOT_FOUND
    || queryCallPayload.data.callInfo.type === SessionResultType.SESSION_NOT_FOUND
    || queryCallPayload.data.note.type === SessionResultType.SESSION_NOT_FOUND
  ) {
    return <Navigate to={routes.sessionWasNotFound} />;
  }

  if (queryCallPayload.data.sessionPayload.type === SessionResultType.SESSION_ENDED
    || queryCallPayload.data.meetingCredentials.type === SessionResultType.SESSION_ENDED
    || queryCallPayload.data.callInfo.type === SessionResultType.SESSION_ENDED
    || queryCallPayload.data.note.type === SessionResultType.SESSION_ENDED
  ) {
    return <Navigate to={routes.sessionEnded} />;
  }

  return (
    <CallAdapter
      sessionApi={sessionApi}
      callInfo={queryCallPayload.data.callInfo.payload}
      currentCall={{
        incomingCall: {
          requestId: sessionId,
          deviceId: queryCallPayload.data.sessionPayload.payload.deviceId,
          callData: queryCallPayload.data.sessionPayload.payload.payload,
          note: queryCallPayload.data.note.payload,
        },
        meetingCredentials: queryCallPayload.data.meetingCredentials.payload,
      }}
    />
  );
};
