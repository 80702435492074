import { useQuery, UseQueryResult } from 'react-query';
import { ReplaceNullableWithOptional } from 'replace-nullable-with-optional';
import { NavigateFunction } from 'react-router-dom';
import assertNever from 'assert-never';

import {
  CreateActivityArgs,
  ActivityData,
  ActivityPair,
  ApsCallData,
  CalculatePaymentsForActivityTickets,
  CallData,
  ChimeMeetingCredentials,
  EntryActivity,
  PaymentFeatures, CallInfo,
} from '@zero5/call-modal';
import {
  connectSession,
  createEntryActivity,
  createExitActivity,
  getSessionCallMeta,
  getSessionPayload,
  getSessionInfo,
  getSuggestedEntryActivitiesByLicensePlate,
  initialize,
  OnMessage,
  openGate,
  updateEntryActivity,
  updateEntryForActivity,
  updateExitActivity, getSessionNote, setSessionNote,
} from '@zero5/session-control-center-api';
import { WsConnectionKeeper } from '@zero5/ui-utils';

import { routes } from '@/routes';

import { SessionResult } from './SessionResult';
import {
  SessionControlCenterActionMessages,
  SessionControlCenterActionType,
} from './SessionControlCenterActionMessages';

export * from './SessionResult';
export * from './SessionControlCenterActionMessages';

export type UpdateActivityDataArgs =
  Partial<ReplaceNullableWithOptional<ActivityData>> & Pick<ActivityData, 'activityId'>;

export interface SessionPayload {
  sessionPayload: SessionResult<{
    deviceId: string;
    payload: CallData | null;
  }>;
  meetingCredentials: SessionResult<ChimeMeetingCredentials>;
  callInfo: SessionResult<CallInfo>;
  note: SessionResult<string | null>;
}

export class SessionApi {
  private readonly apiKey: string;
  private readonly sessionId: string;
  private readonly calleeId: string;

  private readonly navigate: NavigateFunction;
  // @ts-ignore: Unused variable error
  private readonly wsConnectionKeeper: WsConnectionKeeper<OnMessage>;

  constructor(
    apiKey: string,
    sessionId: string,
    calleeId: string,
    navigate: NavigateFunction,
  ) {
    this.apiKey = apiKey;
    this.sessionId = sessionId;
    this.calleeId = calleeId;
    initialize(process.env.API_GATEWAY_URL!, process.env.WEBSOCKET_URL!);

    this.navigate = navigate;
    this.wsConnectionKeeper = this.initWsConnectionKeeper();
  }

  private initWsConnectionKeeper(): WsConnectionKeeper<OnMessage> {
    const wsConnectionKeeper = new WsConnectionKeeper<OnMessage>((
      apiKeyWs: string,
      _garageId: string,
      onMessage: OnMessage,
    ) => {
      return connectSession(apiKeyWs, this.sessionId, onMessage);
    });

    wsConnectionKeeper.openConnection(
      async () => this.apiKey,
      'stub',
      this.onWsMessage.bind(this),
    );

    return wsConnectionKeeper;
  }

  private static GET_SESSION_PAYLOAD_KEY = 'GET_SESSION_PAYLOAD_KEY';
  getSessionPayload(): UseQueryResult<SessionPayload> {
    return useQuery<SessionPayload, unknown, SessionPayload>(
      [SessionApi.GET_SESSION_PAYLOAD_KEY],
      async () => {
        const sessionPayload = await getSessionPayload({
          apiKey: this.apiKey,
          sessionId: this.sessionId,
        });

        const meetingCredentials = await getSessionCallMeta({
          apiKey: this.apiKey,
          sessionId: this.sessionId,
          calleeId: this.calleeId,
        });

        const callInfo = await getSessionInfo({
          apiKey: this.apiKey,
          sessionId: this.sessionId,
        });

        const note = await getSessionNote({
          apiKey: this.apiKey,
          sessionId: this.sessionId,
        });

        return {
          sessionPayload: sessionPayload,
          meetingCredentials,
          callInfo,
          note,
        };
      },
    );
  }

  async getSuggestedEntryActivitiesByLicensePlate(licensePlate: string): Promise<SessionResult<EntryActivity[]>> {
    return getSuggestedEntryActivitiesByLicensePlate({
      apiKey: this.apiKey,
      sessionId: this.sessionId,
      licensePlate,
    });
  }

  async createEntryActivity(data: CreateActivityArgs): Promise<SessionResult<EntryActivity>> {
    return createEntryActivity({
      apiKey: this.apiKey,
      sessionId: this.sessionId,
      data,
    });
  }

  async createExitActivity(data: CreateActivityArgs): Promise<SessionResult<ActivityPair>> {
    return createExitActivity({
      apiKey: this.apiKey,
      sessionId: this.sessionId,
      data,
    });
  }

  async updateEntryActivity(data: UpdateActivityDataArgs): Promise<SessionResult<EntryActivity>> {
    return updateEntryActivity({
      apiKey: this.apiKey,
      sessionId: this.sessionId,
      activityId: data.activityId,
      data,
    });
  }

  async updateExitActivity(data: UpdateActivityDataArgs): Promise<SessionResult<ActivityPair>> {
    return updateExitActivity({
      apiKey: this.apiKey,
      sessionId: this.sessionId,
      activityId: data.activityId,
      data,
    });
  }

  async updateEntryForActivity(
    exitActivityId: string,
    entryActivityId: string,
    deviceId: string,
    tickets?: CalculatePaymentsForActivityTickets,
    paymentFeatures?: PaymentFeatures,
  ): Promise<SessionResult<ApsCallData>> {
    return updateEntryForActivity({
      apiKey: this.apiKey,
      sessionId: this.sessionId,
      exitActivityId,
      entryActivityId,
      tickets,
      paymentFeatures,
    });
  }

  async openGate(): Promise<SessionResult<void>> {
    return openGate({
      apiKey: this.apiKey,
      sessionId: this.sessionId,
    });
  }

  async setNote(note: string | null): Promise<SessionResult<void>> {
    return setSessionNote({
      apiKey: this.apiKey,
      sessionId: this.sessionId,
      note: note ?? undefined,
    });
  }

  private onWsMessage(message: SessionControlCenterActionMessages): void {
    switch (message.type) {
      case SessionControlCenterActionType.SESSION_ENDED_ACTION_MESSAGE:
        this.navigate(routes.sessionEnded);
        break;

      default:
        assertNever(message.type);
    }
  }
}
