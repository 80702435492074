import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { SessionApi } from '@/api/SessionApi';

import { routes } from '@/routes';

interface Props {
  Child: React.JSXElementConstructor<{ sessionApi: SessionApi; sessionId: string; }>;
}

export const SessionApiProvider: React.FC<Props> = ({ Child }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const apiKey = queryParams.get('apiKey');
  const sessionId = queryParams.get('sessionId');
  const calleeId = queryParams.get('userId');

  if (!apiKey || !sessionId || !calleeId) {
    return <Navigate to={routes.invalidRequest} />;
  }

  const navigate = useNavigate();
  const [sessionApi] = useState(new SessionApi(apiKey, sessionId, calleeId, navigate));

  return <Child sessionApi={sessionApi} sessionId={sessionId}/>;
};
