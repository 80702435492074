import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Call, CurrentCall, CallEdition, CallInfo } from '@zero5/call-modal';

import { SessionApi } from '@/api/SessionApi';

import { CallApiProvider } from '@/containers/CallAdapter/CallApiProvider';

interface Props {
  sessionApi: SessionApi;
  callInfo: CallInfo;
  currentCall: CurrentCall;
}

export const CallAdapter: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [callApiProvider, setCallApiProvide] = useState(new CallApiProvider(props.sessionApi, navigate));
  useEffect(() => {
    setCallApiProvide(new CallApiProvider(props.sessionApi, navigate));
  }, [props, navigate]);

  return (
    <Call
      edition={CallEdition.External}
      callInfo={props.callInfo}
      currentCall={props.currentCall}
      callApiProvider={callApiProvider}
    />
  );
};
