import React from 'react';

import { InfoMessage } from '@/components/InfoMessage';

export const SessionEndedPage: React.FC = () => {
  return (
    <InfoMessage
      text="Session ended"
    />
  );
};
