import React from 'react';

import { InfoMessage } from '@/components/InfoMessage';

export const ErrorPage: React.FC = () => {
  return (
    <InfoMessage
      text="Unexpected Error"
    />
  );
};
