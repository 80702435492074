import React from 'react';

import { InfoMessage } from '@/components/InfoMessage';

export const SessionWasNotFoundPage: React.FC = () => {
  return (
    <InfoMessage
      text="Session not found"
    />
  );
};
