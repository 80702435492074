import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
}

export const InfoMessage: React.FC<Props> = ({ text }) => {
  return (
    <StyledText>
      {text}
    </StyledText>
  );
};

const StyledText = styled.text`
  font-size: 40px;
  font-family: Nunito Sans, sans-serif;
`;
